import * as React from 'react'
import loadable from '@loadable/component'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const SelectDay = loadable(() => import('/src/components/Form/Specialised/SelectDay'))
const SelectMonth = loadable(() => import('/src/components/Form/Specialised/SelectMonth'))
const SelectYear = loadable(() => import('/src/components/Form/Specialised/SelectYear'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const StepTrusteeDetails = () => {
    return (
        <>
            <h2 className="c-wizard__step-intro">Trustee Information</h2>

            <div className="c-wizard__form-step">
                <FormRow>
                    <TextField
                        label={"Your Trustee's full legal name"}
                        name={'trusteeName'}
                        placeholder={'e.g. Katie Jones'}
                        required
                    />
                </FormRow>

                <FormRow>
                    <FieldSet>
                        <FieldSpacer grow={'half'}>
                            <TextField
                                label={"Your Trustee's email address"}
                                name={'trusteeEmail'}
                                type={'email'}
                                placeholder={'e.g. name@email.com'}
                            />
                        </FieldSpacer>

                        <FieldSpacer grow={'half'}>
                            <TextField
                                label={`Your Trustee's phone number`}
                                name={'trusteePhone'}
                                format={'number'}
                                placeholder={'e.g. 07700900796'}
                            />
                        </FieldSpacer>
                    </FieldSet>

                    <Alert
                        size={'sm'}
                        compact={true}
                        content={
                            '<p>Trustee details/email address is only used for the purpose of setting up the Trust and will not be used for any other purposes. These fields are not required, but we strongly advise you include them to make sure your application goes through as smoothly as possible.</p>'
                        }
                    />
                </FormRow>

                <FormRow>
                    <FieldSet legend={"Trustee's date of birth*"}>
                        <FieldSpacer grow={'stretch'}>
                            <SelectDay name={'trusteeDobDay'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <SelectMonth name={'trusteeDobMonth'} />
                        </FieldSpacer>

                        <FieldSpacer grow={'stretch'}>
                            <SelectYear name={'trusteeDobYear'} minAge={18} maxAge={90} />
                        </FieldSpacer>
                    </FieldSet>

                    <Alert
                        size={'sm'}
                        compact={true}
                        content={
                            '<p>The Trustee has to be over the age of 18 and a UK resident as they will be the ones dealing with the claims process</p>'
                        }
                    />
                </FormRow>

                <FormRow>
                    <TextField
                        label={"First line of your Trustee's address"}
                        name={'trusteeAddress'}
                        placeholder={'e.g. 105 Dover Road'}
                    />
                </FormRow>

                <FormRow>
                    <TextField label={"Trustee's postcode"} name={'trusteePostcode'} placeholder={'e.g. ZE2 7UL'} />
                </FormRow>
            </div>
        </>
    )
}

export default StepTrusteeDetails
